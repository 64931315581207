import { getLotes, getLotesAcompanhamentos } from 'api/lote'
import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import _ from 'lodash'
import moment from 'moment'
import { call, put, PutEffect, select } from 'redux-saga/effects'
import store from 'store'
import { workerSignOut } from 'store/auth/sagas'
import { IntegradorStore } from 'store/integrador/types'
import { putLoteData } from 'store/lotes/sagas'
import { Lote, LotesAcompanhamentos } from 'store/lotes/types.d'
import { MainConstants } from 'store/main/types.d'
import { AnaliseCrescimentoConstants, DadosAnalise } from './types.d'
import { PlanejamentoSemanalStore } from 'store/planejamentoSemanal/types'

export function* putAnaliseCrescimentoData(payload: unknown): Generator<
  PutEffect<{
    type: AnaliseCrescimentoConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: AnaliseCrescimentoConstants.REDUCER_SET_ANALISE_CRESCIMENTO_DATA,
    payload
  })
}

export function* putMainData(payload: unknown): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}
export function* workerGetDadosAnalise(): any {
  try {
    yield call(putAnaliseCrescimentoData, { analiseCrescimentoLoading: true })

    const { credentials } = store.getState().auth

    const integradorStore: IntegradorStore = yield select((s) => s.integrador)
    const { selectedIntegrador } = integradorStore

    const planejamentoSemanalStore: PlanejamentoSemanalStore = yield select(
      (s) => s.planejamentoSemanal
    )
    const { dataFimPlanejamento } = planejamentoSemanalStore

    const loteParams = {
      // ativo: true,
      fechamentos: false,
      alojamentos: true,
      idIntegrador: selectedIntegrador?.id,
      inicio: moment(dataFimPlanejamento).subtract(50, 'days').format('YYYY-MM-DD'),
      fim: moment(dataFimPlanejamento).format('YYYY-MM-DD')
    }

    const activeLotes: Lote[] = yield call(getLotes, loteParams, credentials.token)
    const acompanhamentos: LotesAcompanhamentos[] = []
    // const pesos: LotesPesos[] = []

    // const params = { nrosLotes: activeLotes.map((l) => l.nroLote) }
    // const params = { ativo: true }
    const params = {
      ativo: false,
      inicio: moment(dataFimPlanejamento).subtract(50, 'days').format('YYYY-MM-DD'),
      fim: moment(dataFimPlanejamento).format('YYYY-MM-DD')
    }

    const acompanhamento: LotesAcompanhamentos[] = yield call(
      getLotesAcompanhamentos,
      params,
      credentials.token
    )
    // const peso: LotesPesos[] = yield call(getLotesPesos, params, credentials.token)
    if (acompanhamento?.length) acompanhamentos.push(...acompanhamento)
    // if (peso?.length) pesos.push(...peso)

    const dadosAnalise: DadosAnalise[] = activeLotes.map((lote) => {
      // const peso = pesos.filter((p) => p.nroLote === lote.nroLote)
      const loteAcompanhamentos = acompanhamentos.filter((a) => a.nroLote === lote.nroLote)
      // const caMedio =
      //   peso.length > 0
      //     ? Number((peso.reduce((acc, cur) => acc + cur.ca, 0) / peso.length).toFixed(3))
      //     : undefined

      const morteManha = _.sumBy(loteAcompanhamentos, 'mortManha')
      const morteTarde = _.sumBy(loteAcompanhamentos, 'mortTarde')
      const elimAscite = _.sumBy(loteAcompanhamentos, 'elimAscite')
      const elimRefugos = _.sumBy(loteAcompanhamentos, 'elimRefugos')
      const elimAleijados = _.sumBy(loteAcompanhamentos, 'elimAleijados')
      const totalAvesAlojadas = lote.qtdAves
      let mortalidade = morteManha + morteTarde + elimAscite + elimRefugos + elimAleijados
      if (mortalidade && totalAvesAlojadas) mortalidade = (mortalidade / totalAvesAlojadas) * 100

      const now = moment()
      const duration = moment.duration(now.diff(moment(lote?.dataNascimento)))
      // The moment duration method return the day + 1 and the SSA does not use + 1
      const days = Number(duration.asDays().toFixed()) - 1
      const idadeLote = days
      return {
        idGranja: Number(lote.alojamentos[0].idGalpao?.slice(0, 3)),
        idNucleo: '0' + Number(lote.alojamentos[0].idGalpao?.slice(4, 5)),
        numeroGalpao: '0' + Number(lote.alojamentos[0].idGalpao?.slice(6, 7)),
        idGalpao: lote.alojamentos[0].idGalpao,
        nroLote: lote.nroLote,
        idadeMatriz: idadeLote,
        dataNascimento: lote.dataNascimento,
        ca: null, // caMedio,
        nroAnimais: totalAvesAlojadas,
        mortalidade: Number(mortalidade?.toFixed(2)),
        raca: lote.linhagem,
        genero: lote.sexo
      }
    })
    yield call(putLoteData, {
      // lotesPesos: pesos,
      // lotes: activeLotes
    })
    yield call(putAnaliseCrescimentoData, {
      dadosAnalise: dadosAnalise.length
        ? _.orderBy(dadosAnalise, ['idGranja', 'idNucleo', 'numeroGalpao'])
        : undefined,
      analiseCrescimentoLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.additionalInfo?.response?.status === 401 || error?.response?.status === 401)
      yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/analiseCrescimento/sagas/workerGetDadosAnalise',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putAnaliseCrescimentoData, { analiseCrescimentoLoading: false })
    yield call(putMainData, { message })
  }
}
