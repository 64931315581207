import produce from 'immer'
import { AnyAction } from 'redux'
import { PlanejamentoSemanalConstants, PlanejamentoSemanalStore } from './types.d'
import moment from 'moment'

const dataInicioPlanejamento = new Date(
  moment().day(0).subtract(2, 'w').format('YYYY-MM-DD')
).toISOString()

// DATE TO TEST
// const dataInicioPlanejamento = new Date('2024-09-01').toISOString()

const INITIAL_STORE: PlanejamentoSemanalStore = {
  // alojamentosAtivos: [],
  filteredAlojamentosAtivos: [],
  alojamentosAtivosLoading: false,
  dataInicioPlanejamento: dataInicioPlanejamento,
  dataFimPlanejamento: new Date(
    moment.utc(dataInicioPlanejamento).add(27, 'd').format('YYYY-MM-DD')
  ).toISOString(),
  primeiraSemanaInicio: moment.utc(dataInicioPlanejamento).add(1, 'd').format('YYYY-MM-DD'),
  primeiraSemanaFim: moment.utc(dataInicioPlanejamento).add(6, 'd').format('YYYY-MM-DD'),
  segundaSemanaInicio: moment.utc(dataInicioPlanejamento).add(8, 'd').format('YYYY-MM-DD'),
  segundaSemanaFim: moment.utc(dataInicioPlanejamento).add(13, 'd').format('YYYY-MM-DD'),
  terceiraSemanaInicio: moment.utc(dataInicioPlanejamento).add(15, 'd').format('YYYY-MM-DD'),
  terceiraSemanaFim: moment.utc(dataInicioPlanejamento).add(20, 'd').format('YYYY-MM-DD'),
  quartaSemanaInicio: moment.utc(dataInicioPlanejamento).add(22, 'd').format('YYYY-MM-DD'),
  quartaSemanaFim: moment.utc(dataInicioPlanejamento).add(27, 'd').format('YYYY-MM-DD'),
  primeiraSemana: [],
  segundaSemana: [],
  terceiraSemana: [],
  quartaSemana: [],
  // planejamentosSemanais: [],
  planejamentosSemanaisLoading: false
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case PlanejamentoSemanalConstants.REDUCER_SET_PLANEJAMENTO_SEMANAL_DATA:
        Object.assign(draft, payload)
        break
      case PlanejamentoSemanalConstants.REDUCER_CLEAR_PLANEJAMENTO_SEMANAL_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
