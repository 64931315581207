import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

// Types
import { ApplicationStore } from 'store/types.d'
import { CardData, Axis } from './types.d'
import { Locales } from 'store/main/types.d'
import { LineChartOptions } from 'components/charts/LineChart/types.d'
import { PlanejamentoSemanalTotais } from 'store/planejamentoSemanal/types'

// Components
import Loading from 'components/shared/Loading'
import LineChart from 'components/charts/LineChart'

// Methods
import { getMediaData, getChartOptions } from './helpers'
import Format from 'helpers/Format'
import { getLotesAtivos } from 'store/lotes/actions'
import { getLotesProcessados } from 'store/dadosProcessados/actions'
import {
  getPlanejamentoSemanalTotais,
  getPlanejamentosSemanais
} from 'store/planejamentoSemanal/actions'

// Resources
import './styles.scss'

// Types
type InnerCardProps = {
  header: string
  content: number | string
  unit?: string
  loading: boolean
}

type MetaCardProps = {
  header: string
  innerCards: InnerCardProps[]
}

const HomeScreen: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { lotesAtivos, lotesAtivosLoading } = useSelector((state: ApplicationStore) => state.lote)
  const { lotesEscaladosProcessados, lotesEscaladosProcessadosLoading } = useSelector(
    (state: ApplicationStore) => state.dadosProcessados
  )
  const { theme, locale } = useSelector((state: ApplicationStore) => state.main)
  const {
    dataInicioPlanejamento,
    dataFimPlanejamento,
    planejamentosSemanais,
    planejamentosSemanaisLoading,
    quartaSemana,
    planejamentoSemanalTotais,
    planejamentoSemanalSummary
  } = useSelector((state: ApplicationStore) => state.planejamentoSemanal)
  const { selectedIntegrador } = useSelector((state: ApplicationStore) => state.integrador)
  const noDataTxt = '-' // t('no_data') ? t('no_data') : 'no_data'
  const [resumoAno, setResumoAno] = useState<Array<CardData>>([])
  const [outroResumoAno, setOutroResumoAno] = useState<Array<CardData>>([])
  const [mortalityDailyData, setMortalityDailyData] = useState<Array<Axis>>()
  const [weightDailyData, setWeightDailyData] = useState<Array<Axis>>()
  const [mortalityChartOptions, setMortalityChartOptions] = useState<LineChartOptions>()
  const [weightChartOptions, setWeightChartOptions] = useState<LineChartOptions>()
  const [mortalityChartColors, setMortalityChartColors] = useState<Array<string>>()
  const [weightChartColors, setWeightChartColors] = useState<Array<string>>()
  const [filteredPlanejamentoSemanalTotais, setFilteredPlanejamentoSemanalTotais] =
    useState<PlanejamentoSemanalTotais>()

  const usePrevious = (value: any) => {
    const ref = useRef()

    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const prevSelectedIntegratorId = usePrevious(selectedIntegrador?.id)

  useEffect(() => {
    setResumoAno([
      {
        header: t('scaled_lots'),
        content:
          filteredPlanejamentoSemanalTotais && filteredPlanejamentoSemanalTotais.lotesTotal
            ? Format(filteredPlanejamentoSemanalTotais?.lotesTotal)
            : noDataTxt,
        loading: planejamentosSemanaisLoading
      },
      {
        header: t('active_lots'),
        content: lotesAtivos ? Format(lotesAtivos) : noDataTxt,
        loading: lotesAtivosLoading
      }
    ])

    let averageSlaughterhouse
    let averageSlaughterhouseFormatted

    if (filteredPlanejamentoSemanalTotais && filteredPlanejamentoSemanalTotais?.mediaAbate) {
      averageSlaughterhouse = Format(filteredPlanejamentoSemanalTotais.mediaAbate)
      averageSlaughterhouseFormatted =
        locale === Locales.en ? averageSlaughterhouse.split(',') : averageSlaughterhouse.split('.')
      const hundred = averageSlaughterhouseFormatted[1]
      averageSlaughterhouse = averageSlaughterhouseFormatted[0] + '.' + hundred[0]
    }

    setOutroResumoAno([
      {
        header: t('average_cicle'),
        content:
          filteredPlanejamentoSemanalTotais && filteredPlanejamentoSemanalTotais?.idadeSaidaMedia
            ? Format(filteredPlanejamentoSemanalTotais?.idadeSaidaMedia)
            : noDataTxt,
        unit: t('days'),
        loading: planejamentosSemanaisLoading
      },
      {
        header: t('average_slaughterhouse'),
        content:
          filteredPlanejamentoSemanalTotais &&
          filteredPlanejamentoSemanalTotais?.animaisPreditosTotal
            ? averageSlaughterhouse + ' K'
            : noDataTxt,
        unit: filteredPlanejamentoSemanalTotais?.pesoPreditoMedio ? t('poultries') : '',
        loading: planejamentosSemanaisLoading
      },
      {
        header: t('average_slaughter_weight'),
        content:
          filteredPlanejamentoSemanalTotais && filteredPlanejamentoSemanalTotais?.pesoPreditoMedio
            ? Format(filteredPlanejamentoSemanalTotais?.pesoPreditoMedio)
            : noDataTxt,
        unit: 'kg',
        loading: planejamentosSemanaisLoading
      }
    ])
  }, [filteredPlanejamentoSemanalTotais, lotesAtivos, locale])

  useEffect(() => {
    if (
      (planejamentoSemanalTotais && prevSelectedIntegratorId !== selectedIntegrador?.id) ||
      !filteredPlanejamentoSemanalTotais
    )
      setFilteredPlanejamentoSemanalTotais(
        _.filter(planejamentoSemanalTotais, { idIntegrador: selectedIntegrador?.id })[0]
      )
  }, [selectedIntegrador, planejamentoSemanalTotais])

  useEffect(() => {
    if (!lotesAtivos || prevSelectedIntegratorId !== selectedIntegrador?.id)
      dispatch(getLotesAtivos(selectedIntegrador?.id))
  }, [lotesAtivos, selectedIntegrador])

  useEffect(() => {
    if (!planejamentosSemanais) dispatch(getPlanejamentosSemanais())
    if (planejamentosSemanais && !planejamentoSemanalTotais) {
      if (quartaSemana.length) dispatch(getPlanejamentoSemanalTotais(quartaSemana))
    }
  }, [selectedIntegrador, planejamentosSemanais, planejamentoSemanalTotais])

  useEffect(() => {
    if (!lotesEscaladosProcessados || prevSelectedIntegratorId !== selectedIntegrador?.id) {
      if (
        (selectedIntegrador && !lotesEscaladosProcessados) ||
        prevSelectedIntegratorId !== selectedIntegrador?.id
      ) {
        setMortalityChartColors(undefined)
        setMortalityDailyData(undefined)
        setWeightChartColors(undefined)
        setWeightDailyData(undefined)
        setMortalityChartOptions(undefined)
        setWeightChartOptions(undefined)
        dispatch(
          getLotesProcessados({
            idIntegrador: selectedIntegrador?.id,
            inicio: dataInicioPlanejamento.split('T')[0],
            fim: dataFimPlanejamento.split('T')[0]
          })
        )
      }
    }
  }, [selectedIntegrador])

  useEffect(() => {
    if (lotesEscaladosProcessados?.galpao) {
      // MORTALIDADE
      if (lotesEscaladosProcessados?.mortalidadeMedia && !mortalityDailyData) {
        // MEDIA
        const data = getMediaData(lotesEscaladosProcessados?.mortalidadeMedia, theme)
        // DIÁRIO
        // const data = getDailyData(lotesEscaladosProcessados?.galpao[0]?.mortalidadeDiaria)
        setMortalityChartColors(data?.colors)
        setMortalityDailyData(data?.axisValues)
      }
      // PESO
      if (lotesEscaladosProcessados?.pesoMedio && !weightDailyData) {
        // MEDIA
        const data = getMediaData(lotesEscaladosProcessados?.pesoMedio, theme)
        // DIÁRIO
        // const data = getDailyData(lotesEscaladosProcessados?.galpao[0]?.pesoDiario)
        setWeightChartColors(data?.colors)
        setWeightDailyData(data?.axisValues)
      }
    }
  }, [lotesEscaladosProcessados])

  useEffect(() => {
    if (mortalityChartColors && !mortalityChartOptions) {
      const mortalityChartOptions = getChartOptions(
        mortalityChartColors,
        t('mortality') + ', ' + t('poultry_number'),
        t('day') + ', ' + t('unity:d')
      )
      setMortalityChartOptions(mortalityChartOptions)
    }
  }, [mortalityChartColors])

  useEffect(() => {
    if (weightChartColors && !weightChartOptions) {
      const weightChartOptions = getChartOptions(
        weightChartColors,
        t('weight') + ', ' + t('unity:kg'),
        t('day') + ', ' + t('unity:d')
      )
      setWeightChartOptions(weightChartOptions)
    }
  }, [weightChartColors])

  function InnerCard({ header, content, unit, loading }: InnerCardProps) {
    return (
      <div className='innerCard'>
        <p className='innerCard-title'>{header}</p>
        <div>
          {loading ? <Loading /> : content}{' '}
          {unit && content !== t('no_data') && content !== 'no_data' ? (
            <small>{unit}</small>
          ) : undefined}
        </div>
      </div>
    )
  }

  function MetaCard({ header, innerCards }: MetaCardProps) {
    const cards = innerCards.map(({ header, content, unit, loading }) => (
      // TODO: Change key to id from DB
      <InnerCard key={header} header={header} content={content} unit={unit} loading={loading} />
    ))
    return (
      <div className='card-container'>
        <p className='card-title'>{header}</p>
        <div>{cards}</div>
      </div>
    )
  }

  return (
    <div className='home-container'>
      <div className='container'>
        <MetaCard header={t('my_lots')} innerCards={resumoAno} />
        <MetaCard header={t('lots_to_leave')} innerCards={outroResumoAno} />
        <div className='card-container'>
          <p className='card-title'>{t('daily_average_weight')}</p>
          <div className='graph'>
            {lotesEscaladosProcessadosLoading ? (
              <Loading />
            ) : (
              <LineChart values={weightDailyData} chartOptions={weightChartOptions} />
            )}
          </div>
        </div>
        <div className='card-container'>
          <p className='card-title'>{t('mortalidade_acumulada_media')}</p>
          <div className='graph'>
            {lotesEscaladosProcessadosLoading ? (
              <Loading />
            ) : (
              <LineChart values={mortalityDailyData} chartOptions={mortalityChartOptions} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
